import React, { useState, useEffect } from 'react';
import './Auth.css';
import { Input, Divider } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Link } from "react-router-dom";
import image from '../../Images/Logo.png'
import {AnimatedDiv} from '../AnimatedDiv'
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

export default function Default ()
{
  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
});

useEffect(
  () => {
      if (user) {
          axios
              .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                  headers: {
                      Authorization: `Bearer ${user.access_token}`,
                      Accept: 'application/json'
                  }
              })
              .then((res) => {
                  setProfile(res.data);
              })
              .catch((err) => console.log(err));
      }
  },
  [ user ]
);
const logOut = () => {
  googleLogout();
  setProfile(null);
};
  return (
  <>  
    <AnimatedDiv className='auth-container' delay={0}>
      <div className='auth-container-child'>
        <div className="auth-logo"> 
            <img className="auth-logo-image" alt='' src={image}/>
            <span className='auth-logo-text'>Florida Best Getaways</span>
        </div>        
        <div className='auth-button-container'>
 
              <button className="auth-button-facebook">
              <svg stroke="#ffffff" viewBox="-143 145 512 512" xmlns="http://www.w3.org/2000/svg" id="Layer_1" version="1.1" fill="#ffffff">
                <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                <g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g>
                <g id="SVGRepo_iconCarrier"> 
                  <path d="M329,145h-432c-22.1,0-40,17.9-40,40v432c0,22.1,17.9,40,40,40h432c22.1,0,40-17.9,40-40V185C369,162.9,351.1,145,329,145z M169.5,357.6l-2.9,38.3h-39.3v133H77.7v-133H51.2v-38.3h26.5v-25.7c0-11.3,0.3-28.8,8.5-39.7c8.7-11.5,20.6-19.3,41.1-19.3 c33.4,0,47.4,4.8,47.4,4.8l-6.6,39.2c0,0-11-3.2-21.3-3.2c-10.3,0-19.5,3.7-19.5,14v29.9H169.5z"></path> 
                </g>
              </svg>
                Continue with Facebook
              </button>

              <button className="auth-button-google" onClick={() => login()}>
                  <svg viewBox="0 0 48 48">
                <clipPath id="g">
                  <path
                    d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                  ></path>
                </clipPath>
                <g clip-path="url(#g)" class="colors">
                  <path d="M0 37V11l17 13z" fill="#FFF"></path>
                  <path d="M0 11l17 13 7-6.1L48 14V0H0z" fill="#FFF"></path>
                  <path d="M0 37l30-23 7.9 1L48 0v48H0z" fill="#FFF"></path>
                  <path d="M48 48L17 24l-4-3 35-10z" fill="#FFF"></path>
                </g>
              </svg>
              Continue with Google
            </button>          
              
        </div>
        <Divider>
        <span style={{color: "#585858", fontSize: 14}}>OR</span></Divider>
        <div className="auth-form-container">
          <div className="auth-form-item">
            <p>Email</p>
            <Input placeholder="Set the Email address" iconRender={<fgf />} />
          </div>
          <div className="auth-form-item">
            <p>Password</p>
            <Input.Password placeholder="Set the Password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </div>
          <button className="auth-form-auth">Sing In</button>

          <p className="auth-form-have-account">Don’t have an account? <a><Link to='/register'>Sign up</Link></a></p>
        </div>
      </div>
    </AnimatedDiv>


    
    </>
  );
};
