import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu'
import './NavBar.css';
import image from '../../Images/Logo.png'
import { Link, useLocation } from "react-router-dom";

export default function NavigationMenu()
{
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu open/close
    const location = useLocation();
    const isHomePage = location.pathname === '/' || location.pathname === '/home';

    useEffect(() => {
      const handleScroll = () => {
        if (window.pageYOffset > 0) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle the menu open/close state
    };

    return (
    <>
    <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu" />
    <div className={`menu-container ${isScrolled || !isHomePage ? 'scrolled' : ''}`}>

    <div className="sidebarMenu-windows">
      <div className="menu-logo">
        <img className="menu-logo-image" alt='' src={image} />
        <span className='menu-logo-text'>Florida Best Getaways</span>
      </div>
        <Link className='menu-items-link' to='/home'>HOME</Link>
        <Link className='menu-items-link' to='/about'>ABOUT US</Link>
        <Link className='menu-items-link' to='/signin'>SIGN IN</Link>
        <Link className='menu-items-link' to='/register'>REGISTER</Link>
    </div>
    </div>
        <label for="openSidebarMenu" className="sidebarIconToggle">
            <div className="spinner diagonal part-1"></div>
            <div className="spinner horizontal"></div>
            <div className="spinner diagonal part-2"></div>
        </label>
        <div className="sidebarMenu">
          <Link className='menu-items-link' to='/home'>HOME</Link>
          <Link className='menu-items-link' to='/about'>ABOUT US</Link>
          <Link className='menu-items-link' to='/signin'>SIGN IN</Link>
          <Link className='menu-items-link' to='/register'>REGISTER</Link>
        </div>
    </>
    )
};

const SignInOnClick = () => {
    // Lógica para el evento onClick del elemento "ABOUT US"
    console.log("ABOUT US clicked");
};

const RegisterOnClick = () => {
    // Lógica para el evento onClick del elemento "ABOUT US"
    console.log("ABOUT US clicked");
};

