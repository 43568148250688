import './HouseDetails.css';
import HouseCard from '../HouseCard/HouseCard';
import  { AnimatedDiv } from '../AnimatedDiv'

import House1 from '../../Images/Houses/1/1.jpg'
import House2 from '../../Images/Houses/2/1.jpg'
import House3 from '../../Images/Houses/3/1.jpg'

export default function Example()
{
    return (
    <>
        <AnimatedDiv className='room-title' delay={0.40} >
            Rooms & Apartments
        </AnimatedDiv>
        <AnimatedDiv className='room-details' delay={0.55} >
            Find Your Rooms, for your abaility
        </AnimatedDiv>

        <div className="grid-container">
        <AnimatedDiv className="grid-item" >
            <HouseCard title={"Casita mi Encanto"} ubication={"240 Old Mill Circle Kissimmee, FL"} bedrooms={4} bathrooms={3} price={197} image={House1} rate={4} />
        </AnimatedDiv>
        <AnimatedDiv className="grid-item"  delay={0.40}>
            <HouseCard title={"Tranquility Villa"} ubication={"2638 Tranquility Way Kissimmee, FL"} bedrooms={4} bathrooms={4} price={187} image={House2} rate={5}/>
        </AnimatedDiv>
        <AnimatedDiv className="grid-item"  delay={0.80}>
            <HouseCard title={"Beach Paradise"} ubication={"3505 S Ocean Dr #618, Hollywood, FL"} bedrooms={1} bathrooms={"1 1/2"} price={591} image={House3} rate={3.5} />
        </AnimatedDiv>
        </div>
    </>
    )
};