import './HouseCard.css';
import { Rate, Button } from 'antd';

export default function Example({title, ubication, bedrooms, bathrooms, price, image, rate})
{
    return (
  <article className='house-card-article'>
    <header className='house-card-header'>     
    
        <img className='house-card-img' src={image} alt="" />
        <div className='house-card-blur'>
            <h3 className='house-card-title'>{title}</h3>
            <Rate className='house-card-rate' disabled defaultValue={rate} />
        </div> 
    </header>
    <section className="ubication-container">
      {/*<h3 className='house-card-title'>{title}</h3>  */}
      <div>
        <i className="svg-ubication"></i>
        <span className='house-card-info'>{ubication}</span>
      </div>
    </section>
    <section >
      <div className="separator"> 
      </div>
      <div className='house-card-info'>
        <i className="svg-Bedroom"></i>
        <span> {bedrooms} Bedrooms </span>
        <i className="svg-Bathroom"></i>
        <span>{bathrooms} Bathrooms </span>
      </div>
    </section>

    <section >
      <div className='house-card-price'>
      ${price} per night
      </div>
    </section>
    <section>
      <div className='house-card-button-container'>
      <Button className='house-card-button'>Book now</Button>
      </div>
    </section>
  </article>
    )
};