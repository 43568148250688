import HouseDetails from './HouseDetails/HouseDetails'
import HouseArt from './HouseArt/HouseArt'
import HeaderSection from '../Components/HeaderSection/HeaderSection';
import * as React from 'react'

export default function Home ()
{
    return (
    <>
        <HeaderSection />
        <HouseDetails />
        <HouseArt />
    </>
    )
};