import './App.css';

import FooterSection from './Components/FooterSection/FooterSection';
import NavBar from './Components/NavBar/NavBar';
import Home from './Components/Home';
import Empty from './Components/Empty';
import Register from './Components/Auth/Register';
import SignIn from './Components/Auth/SignIn';
import {BackToTop} from './Components/BackToTop/BackToTop';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as React from 'react'
import { Layout } from 'antd';

const { Content } = Layout;

export default function App() {
  return (
      <>
      <Router>
    
      <NavBar />

      <Content>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<Empty />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </Content>

      <FooterSection />
      <BackToTop  />
      </Router>
      </>
  );
}

