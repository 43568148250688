import './FooterSection.css';
import { QRCodeSVG} from 'qrcode.react';
  
const vCardData = `BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:Juan Carlos Delgado
ORG;CHARSET=UTF-8:Florida house booking
TEL;TYPE=Phone number:+1 (786) 444-0073
EMAIL;CHARSET=UTF-8;type=INTERNET,WORK:delgado.cruz.holdings@gmail.com
ADR;TYPE=work:10550 NW 77th CT 208-212 Hialeah Gardens FL 33016
street: '123 Main St',
city: 'City',
state: 'State',
postalCode: 'Zip',
country: 'Country',
END:VCARD`;

export default function Example({title, ubication, bedrooms, bathrooms, price, image})
{
    return (
<footer>
    <div className='column-Container'>
        <div className="column-contact-us">
            <div>
                <p className='contact-us'>Contact us</p>
            </div>
            <div className='contact-us-details'>
                <svg className='contact-us-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="13" height="13">
                <path d="M22.386719 18.027344C20.839844 16.703125 19.265625 15.898438 17.738281 17.222656L16.824219 18.019531C16.15625 18.601563 14.914063 21.3125 10.113281 15.785156C5.3125 10.269531 8.167969 9.410156 8.839844 8.835938L9.757813 8.035156C11.277344 6.710938 10.703125 5.042969 9.605469 3.324219L8.945313 2.285156C7.84375 0.574219 6.640625 -0.550781 5.117188 0.769531L4.292969 1.492188C3.617188 1.980469 1.734375 3.578125 1.277344 6.609375C0.726563 10.246094 2.464844 14.414063 6.4375 18.984375C10.40625 23.558594 14.296875 25.855469 17.976563 25.816406C21.035156 25.78125 22.886719 24.140625 23.464844 23.542969L24.289063 22.820313C25.8125 21.5 24.867188 20.152344 23.316406 18.828125Z" fill="#BEBEBE" />
                </svg>
                <a className='contact-us-details' href='tel:+17864440073'>+1 (786) 444-0073</a>
            </div>
            <div className='contact-us-details'>
                <svg className='contact-us-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
                <path d="M4 4C3.07 4 2.2923125 4.6429063 2.0703125 5.5039062L12 11.726562L21.935547 5.5214844C21.719547 4.6504844 20.937 4 20 4L4 4 z M 2 7.734375L2 18C2 19.103 2.897 20 4 20L20 20C21.103 20 22 19.103 22 18L22 7.7558594L12 14L2 7.734375 z" fill="#BEBEBE" />
                </svg>
                <a className='contact-us-details' href='mailto:delgado.cruz.holdings@gmail.com'>delgado.cruz.holdings@gmail.com</a>
            </div> 
            <div className='contact-us-details'>
                <svg className='contact-us-svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='15' height='15'>
                <path d='M12,2C8.134,2,5,5.134,5,9c0,5,7,13,7,13s7-8,7-13C19,5.134,15.866,2,12,2z M12,11.5c-1.381,0-2.5-1.119-2.5-2.5 c0-1.381,1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5C14.5,10.381,13.381,11.5,12,11.5z' fill='#BEBEBE' />
                </svg>
                <a className='contact-us-details' href=''>10550 NW 77th CT 208-212 Hialeah Gardens FL 33016 </a>
            </div>
        </div>
        <div className="column-QR">
            <div className='QR-container'> 
                <QRCodeSVG value={vCardData} color={"#FFF"}/>
            </div>
        
        </div>
        <div className="contact-us-column">
        </div>
  </div>
  <div className='copyright'>
    <div className='copyright-separator'></div>
    <div className='copyright-text'>Copyright 2024. All Rights Reserved.</div>
  </div>
  
</footer>
    )
};